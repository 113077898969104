import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['reset', 'resetUser', 'originate'];

  checkStatus(event) {
    const { response } = event.detail.fetchResponse;

    let message;
    response.text().then((data) => {
      if (response?.ok) {
        message = JSON.parse(data)?.message;
        message && alert(message);

        if (this.hasResetTarget) {
          const firstInputWithClass = document.querySelector('tr.collaps.show p.reset');
          if (firstInputWithClass) {
            firstInputWithClass.style.display = 'none';
          }
        }

        if (this.hasResetUserTarget) {
          const firstInputWithClass = document.querySelector('tr.collaps.show p.reset');
          if (firstInputWithClass) {
            firstInputWithClass.style.display = 'none';
            firstInputWithClass.parentElement.parentElement.previousElementSibling.querySelector('td:last-of-type').innerText = 'Yes'
          }
        }

        if (this.hasOriginateTarget) {
            const firstInputWithClass = document.querySelector('tr.collaps.show');
            if (firstInputWithClass) {
              firstInputWithClass.style.display = 'none';
              firstInputWithClass.previousElementSibling.style.display = 'none';
            }
        }
      } else {
        message = JSON.parse(data)?.errors;
        message && alert(message);
      }
    });
  }
}
