import "./controllers";
import "./common_functions";
import "./scripts";
import "./filters";
import "./refunds";
import "./trendings";

import "@coreui/coreui";
import "@coreui/chartjs";
import "@hotwired/turbo-rails";

import "chart.js";

import moment from "moment";

moment.locale("en");
