import {getCSRFToken} from "./common_functions";

document.addEventListener("turbo:load", function () {
  if (document.querySelector('.trendings')) {
    initTrendingDiagrams();
  }
});

const arrayOfCharts = [];

export function initTrendingDiagrams(dateFrom = new Date().addDays(-8), dateTo = new Date()) {
  fetch('/trendings/filtered', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "X-Csrf-Token": getCSRFToken(),
    },
    body: JSON.stringify({ date_from: dateFrom, date_to: dateTo }),
  })
    .then(response => response.json())
    .then(data => {
      if (Object.keys(data?.trendings).length) {
        prepareTrendingCharts(data, dateFrom, dateTo);
      } else {
        toggleNoData();
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
}

window.prepareTrendingCharts = function(data, dateFrom, dateTo) {
  const trendings = Object.keys(data.trendings);

  arrayOfCharts.forEach((chart, i) => chart.destroy());
  togglingDisplayTrendingsCharts();

  trendings.forEach((trending, i) => {
    let filledTrandingsByDate = fillObjectByDates(data.trendings[trending], dateFrom, dateTo),
      sortedTrendingsByDate = sortByDate(filledTrandingsByDate);
    let countOfVisits = getCountOfVisits(sortedTrendingsByDate),
      changesOfVisits = getChangesOfVisits(filledTrandingsByDate),
      canvasID = trending + "_chart",
      canvasContext = document.getElementById(canvasID).getContext("2d"),
      labels = Object.keys(filledTrandingsByDate),
      tableClass = trending + "_table";
    labels.shift();
    initTrendingCharts(canvasContext, labels, countOfVisits, changesOfVisits);
    if (trending !== 'select_provider')
      insertTableForVisits(tableClass, labels, countOfVisits, changesOfVisits);
    else
      insertLargeTableForVisits(tableClass, labels, countOfVisits, changesOfVisits, data.providers);
  });
};

window.initTrendingCharts = function(ctx, labels, counts, changes) {
  const chartData = {
    labels: labels,
    datasets: [{
      type: 'line',
      label: 'Count',
      borderColor: "#ed6a5a",
      borderWidth: 2,
      fill: false,
      data: counts
    }, {
      type: 'bar',
      label: 'Changes',
      backgroundColor: "#053c5e",
      data: changes,
      borderColor: 'white',
      borderWidth: 2
    }]
  };

  const chart = new Chart(ctx, {
    type: 'bar',
    data: chartData,
    options: {
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: true
      }
    }
  });
  arrayOfCharts.push(chart);
};

window.insertTableForVisits = function(tableClass, labels, counts, changes) {
  let template = "";

  labels.forEach((label, i) => {
    template += "<tr><td>" + formatDate(label) + "</td><td>" + counts[i] + "</td><td>" + changes[i] + "</td></tr>";
  });
  document.querySelector('.' + tableClass).innerHTML = template;
};

window.insertLargeTableForVisits = function(tableClass, labels, counts, changes, providers) {
  let template = "";

  labels.forEach((label, i) => {
    if(typeof providers[label] !== 'undefined') {

      let lines = "";
      Object.keys(providers[label]).forEach(key => {
        lines += "<tr><td>" + key + "</td><td>" + providers[label][key] + "</td></tr>";
      });
      let provider_table = "<table class=\"table no-border-bottom mb-0 table-responsive-sm\"><tbody>" + lines + "</tbody></table>"

      template += "<tr><td>" + formatDate(label) + "</td><td>" + counts[i] + "</td><td>" + changes[i] + "</td><td class=\"p-0\">" + provider_table + "</td></tr>";
    } else
      template += "<tr><td>" + formatDate(label) + "</td><td>" + counts[i] + "</td><td>" + changes[i] + "</td><td> - </td></tr>";
  });

  document.querySelector('.' + tableClass).innerHTML = template;
}

window.fillObjectByDates = function(data, dateFrom, dateTo) {
  const dates = trendingGetDates(dateFrom, dateTo),
    filledObject = {};
  dates.forEach((date, i) => {filledObject[date] = data[date] || 0});

  return filledObject;
};

window.getCountOfVisits = function(data) {
  const newArrayOfCount = Object.values(data);
  newArrayOfCount.shift();

  return newArrayOfCount;
};

window.getChangesOfVisits = function(data) {
  let arrayOfCount = Object.values(data),
    newArrayOfChanges = [];

  for(let i = 1; i < arrayOfCount.length; i++){
    let percentOfChange = (arrayOfCount[i-1]-arrayOfCount[i])*(-1);
    if(arrayOfCount[i-1] !== 0) {
      percentOfChange = (percentOfChange*100)/arrayOfCount[i-1];
    } else {
      percentOfChange = percentOfChange*100;
    }

    newArrayOfChanges.push(percentOfChange.toFixed(2));
  }

  return newArrayOfChanges;
};

export function togglingDisplayTrendingsCharts() {
  document.querySelector('.trending_table_body').innerHTML = '';
  const canvases = document.querySelectorAll('canvas');
  canvases.forEach(canvas => {
    canvas.style.display = 'block';
  });
  const noDataElements = document.querySelectorAll('.no-data');
  noDataElements.forEach(element => {
    element.style.display = 'none';
  });
  const skWaveElements = document.querySelectorAll('.sk-wave');
  skWaveElements.forEach(element => {
    element.classList.toggle('active');
  });
}

function toggleNoData(screen) {
  const canvases = document.querySelectorAll('canvas');
  canvases.forEach(canvas => {
    canvas.style.display = canvas.style.display === 'none' ? 'block' : 'none';
  });
  const noDataElements = document.querySelectorAll('.no-data');
  noDataElements.forEach(element => {
    element.style.display = element.style.display === 'none' ? 'block' : 'none';
  });
  const skWaveElements = document.querySelectorAll('.sk-wave');
  skWaveElements.forEach(element => {
    element.classList.toggle('active');
  });
}

function sortByDate(visits) {
  const newSortedObject = {};
  const sortedArray = Object.keys(visits).sort((a, b) => {
    return new Date(a) - new Date(b);
  });

  sortedArray.forEach(date => {
    newSortedObject[date] = visits[date];
  });

  return newSortedObject;
}
