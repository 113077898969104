import { easepick } from "@easepick/core";
import { RangePlugin } from "@easepick/range-plugin";
import { LockPlugin } from "@easepick/lock-plugin";

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

window.getDates = function (startDate, stopDate) {
  let dateArray = [],
    currentDate = new Date(startDate);
  while (currentDate <= new Date(stopDate)) {
    dateArray.push(new Date(currentDate).toISOString().slice(0, 10));
    currentDate = new Date(currentDate).addDays(1);
  }
  return dateArray;
};

window.trendingGetDates = function (startDate, stopDate) {
  let dateArray = [],
    currentDate = new Date(startDate);
  while (currentDate <= new Date(stopDate)) {
    dateArray.push(formatDate(new Date(currentDate))); //.toISOString().slice(0, 10));
    currentDate = new Date(currentDate).addDays(1);
  }
  return dateArray;
};

window.formatDate = function (date) {
  date = new Date(date);
  let day = date.getDate(),
    month = date.getMonth() + 1,
    year = date.getFullYear(),
    padDay = day < 10 ? "0" + day : day,
    padMonth = month < 10 ? "0" + month : month;

  return year + "-" + padMonth + "-" + padDay;
};

window.initDatePicker = function ({ element, rangeOptions, setup }) {
  let maxDate = new Date(),
    minDate = maxDate.addDays(-365);

  const range = Object.assign(
    { tooltip: true, startDate: maxDate.addDays(-7), endDate: maxDate },
    rangeOptions
  );

  new easepick.create({
    element: element,
    css: [
      "https://cdn.jsdelivr.net/npm/@easepick/core@1.2.1/dist/index.css",
      "https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.1/dist/index.css",
      "https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.1/dist/index.css",
    ],
    plugins: [RangePlugin, LockPlugin],
    zIndex: 100,
    autoApply: false,
    format: "DD MMM YYYY",
    LockPlugin: {
      minDate: minDate,
      maxDate: maxDate,
    },
    RangePlugin: {
      ...range,
    },
    ...setup,
  });

};

window.setDatesToStorage = function (
  from = new Date().addDays(-31),
  to = new Date(),
  status = "init"
) {
  localStorage.setItem("dates", JSON.stringify({ from, to, status }));
};

window.getDatesFromStorage = function () {
  let dates = localStorage.getItem("dates");
  if (dates !== null) {
    dates = JSON.parse(dates);
  } else {
    dates = {
      from: new Date().addDays(-31),
      to: new Date(),
    };
  }
  return dates;
};

export function getCSRFToken() {
  const tokenTag = document.querySelector('meta[name="csrf-token"]');
  return tokenTag ? tokenTag.content : "";
}
