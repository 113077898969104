import { Controller } from "@hotwired/stimulus";
import NiceSelect from "nice-select2/dist/js/nice-select2";
import Spectrum from "spectrum-vanilla/dist/spectrum";
import Pristine from "pristinejs";
import {getCSRFToken} from "../common_functions";

export default class extends Controller {

  static targets = [
    "searchSelect",
    "merchantLogo",
    "merchantPreview",
    "removeLogo",
    "mainColorPicker",
    "additionalColorPicker",
    "merchantFields",
    "merchantFieldsActive",
    "removeAdditionalField",
    "merchantRemoveLogo",
    "searchSelect",
    "form",
  ];

  connect() {
    this.setColorPicker();
    this.initSelectElement();
  }

  initSelectElement() {
    const selectElements = this.searchSelectTargets;

    if (selectElements?.length) {
      selectElements.forEach((element) => {
        const nextElement = element?.nextElementSibling;
        if (!nextElement || !nextElement?.classList?.contains("nice-select")) {
          element.style.display = "none";
          new NiceSelect(element, {});
        }
      });
    }
  }

  togglePassword(event) {
    const visibilityIcon = event.target;
    const input = visibilityIcon.previousElementSibling;

    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
      visibilityIcon.textContent = "visibility";
    } else {
      input.setAttribute("type", "password");
      visibilityIcon.textContent = "visibility_off";
    }
  }

  toggleOtpVisibility(event) {
    const otpTarget = event.target;
    const input = otpTarget.parentElement.nextElementSibling;
    input.style.display = event.target.checked ? 'flex' : 'none';
  }

  addAdmin(event) {
    event.preventDefault();
    const dateToInt = Number(new Date());
    const nameInputEmail = `merchant[admins_attributes][${dateToInt}][email]`;
    const nameInputName = `merchant[admins_attributes][${dateToInt}][name]`;
    const nameSelectRole = `merchant[admins_attributes][${dateToInt}][role]`;

    const newAdminFields = document.createElement("div");
    newAdminFields.classList.add("admin-fields");
    newAdminFields.innerHTML = `
    <div class='form-group row'>
      <label class='col-md-3 col-form-label text-right' for='merchant_admins_attributes_${dateToInt}_email'>Admin Email</label>
      <div class='col-md-9 field-holder'>
        <input autofocus='autofocus' required autocomplete='email' class='form-control valid' type='email' value='' name='${nameInputEmail}' id='merchant_admins_attributes_${dateToInt}_email' aria-invalid='false'>
        <span class='has_error help-block'></span>
      </div>
    </div>
    <div class='form-group row'>
      <label class='col-md-3 col-form-label text-right' for='merchant_admins_attributes_${dateToInt}_name'>Admin Name</label>
      <div class='col-md-9 field-holder'>
        <input autofocus='autofocus' required minlength=3" maxlength="50" autocomplete='name' class='form-control valid' type='text' value='' name='${nameInputName}' id='merchant_admins_attributes_${dateToInt}_name' aria-invalid='false'>
        <span class='has_error help-block'></span>
      </div>
    </div>
    <div class='form-group row' data-controller="forms">
      <label class='col-md-3 col-form-label text-right' for='merchant_admins_attributes_${dateToInt}_role'>Admin Role</label>
      <div class='col-md-9 field-holder'>
        <select name='${nameSelectRole}' id='merchant_admins_attributes_${dateToInt}_role' class='search_select additional' data-forms-target="searchSelect">
          <option value='admin'>Admin</option>
          <option value='manager'>Manager</option>
          <option value='user_manager'>User Manager</option>
        </select>
        <span class='has_error help-block'></span>
      </div>
    </div>
    <i class='c-icon c-icon-xl cil-x remove-additional-field' data-action="click->forms#removeAdmin"></i>
  `;

    const adminFields = document.querySelectorAll(".admin-fields");
    const lastField = adminFields?.[adminFields?.length - 1];
    lastField.parentNode.insertBefore(newAdminFields, lastField.nextSibling);

    this.initSelectElement();
  }

  navigateMerchantFields(event) {
    event.preventDefault();
    document.querySelectorAll(".merchant-fields")?.forEach((el) => {
      el.classList.toggle("active");
    });
  }

  removeAdmin(event) {
    const adminFields = event.target.closest(".admin-fields");
    adminFields.style.display = "none";
    const hiddenDestroyInput = adminFields.querySelector(".hidden_destroy");
    if (hiddenDestroyInput) hiddenDestroyInput.value = "true";
  }

  onLogoChange(event) {
    let img;
    const preview = document.querySelector("#preview-logo");
    if (preview) {
      img = preview;
    } else {
      img = document.createElement("img");
      img.id = "preview-logo";
      img.className = "small-logo";
    }

    const fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize > 1) {
      this.removeLogoData();
      alert("File size exceeds 1 MB");
    } else {
      img.src = URL.createObjectURL(event.target.files[0]);
      this.merchantPreviewTarget.parentNode.insertBefore(
        img,
        this.merchantPreviewTarget.nextSibling
      );
      this.merchantRemoveLogoTarget.value = "false";
      this.removeLogoTarget.classList.add("show");
    }
  }

  onRemoveClick(event) {
    event.preventDefault();
    this.removeLogoData();
  }

  removeLogoData() {
    document.querySelector("#preview-logo").remove();
    this.merchantLogoTarget.value = null;
    this.merchantRemoveLogoTarget.value = "true";
    this.removeLogoTarget.classList.remove("show");
  }

  setColorPicker() {
    const mainColor = document.querySelector(".main-color-picker");
    const additionalColor = document.querySelector(".additional-color-picker");

    if (mainColor) {
      Spectrum.getInstance(".main-color-picker", {
        color: mainColor.value,
        showInput: true,
      });
    }

    if (additionalColor) {
      Spectrum.getInstance(".additional-color-picker", {
        color: additionalColor.value,
        showInput: true,
      });
    }
  }

  validateForm() {
    const form = this.formTarget;

    if (!form) return;

    let defaultConfig = {
      classTo: "field-holder",
      errorClass: "has_error",
      errorTextParent: "field-holder",
      errorTextTag: "i",
    };

    const pristine = new Pristine(form, defaultConfig);
    const isValid = pristine.validate();

    Pristine.addValidator(
      "password-strong",
      function (inputValue) {
        const pattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$%^&*()\-_=+\\|[\]{};:'",.<>/?`~])(?!.*\s).{6,}$/;
        const isValid = pattern.test(inputValue);
        return !!isValid;
      },
      "Password must be strong: at least 6 characters, with lowercase, uppercase, digits, and special characters",
      2,
      false
    );

    form.addEventListener("submit", async function (event) {
      event.preventDefault();
      if (isValid) {
        form.submit();
      }
    });
  }
}
