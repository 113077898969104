import { Controller } from "@hotwired/stimulus";
import {getCSRFToken} from '../common_functions';

export default class extends Controller {
  static targets = [
    "reportType",
    "subscribersForm",
    "subscribersEmails",
    "subscribersCreate",
  ];

  initialize() {
    this.initEventListeners();
  }

  initEventListeners() {
    document.querySelectorAll('.subscribed_email').forEach((element) => {
      if (!element.dataset.subscribedEmailListener) {
        element.dataset.subscribedEmailListener = true;
        element.addEventListener('click', this.addInput.bind(this));
      }
    });

    document.querySelectorAll('.remove-subscribed-email').forEach((element) => {
      if (!element.dataset.subscribedRemoveListener) {
        element.dataset.subscribedRemoveListener = true;
        element.addEventListener('click', this.removeSubscriber.bind(this));
      }
    });
  }

  createSubscribers(event) {
    event.preventDefault();

    const parent = this.subscribersFormTarget;
    const emails = this.subscribersEmailsTarget?.value;
    const frequency = parent.querySelector('input[type="radio"]:checked')?.value;
    const reportType = this.reportTypeTarget?.value;
    const merchantID = this.subscribersCreateTarget?.dataset?.merchantId;
    const dates = JSON.parse(localStorage.getItem("dates"));
    const dateFrom = dates.from;
    const dateTo = dates.to;

    const requestData = {
      subscribers: {
        emails: emails,
        frequency: frequency,
        report_type: reportType,
        merchant_id: merchantID,
        date_from: dateFrom,
        date_to: dateTo,
      },
    };

    fetch("/subscribers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Csrf-Token": getCSRFToken(),
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["subscribers"].length) {
          data["subscribers"].forEach((subscriber) => {
            const classReportInfo = `.${subscriber[2]}.${subscriber[3]}`;
            this.appendSubscriberInfo(
              classReportInfo,
              subscriber[1],
              subscriber[0]
            );
          });
        }
        this.subscribersEmailsTarget.value = "";
      })
      .catch((error) => {
        console.error("Error:", error);
        this.subscribersEmailsTarget.value = "";
      });
  }

  appendSubscriberInfo(classReportInfo, email, id) {
    const newSubscriber = document.createElement("span");
    newSubscriber.classList.add("subscribed_email");
    newSubscriber.dataset.email = email;
    newSubscriber.dataset.id = id;
    newSubscriber.textContent = email;
    newSubscriber.addEventListener("click", this.addInput.bind(this));
    newSubscriber.innerHTML += '<i class="remove-subscribed-email"> X </i>';
    newSubscriber.querySelector(".remove-subscribed-email").addEventListener('click', this.removeSubscriber.bind(this));

    document.querySelector(classReportInfo).appendChild(newSubscriber);
  }

  addInput(event) {
    if (!event.target.classList.contains("remove-subscribed-email")) {
      const parentEl = event.currentTarget;
      const subscriberID = parentEl.dataset.id;
      const subscriberEmail = parentEl.dataset.email;
      if (parentEl.querySelector(".inserted_input") === null) {
        parentEl.innerHTML = `<input autoFocus class="inserted_input" data-id="${subscriberID}" type="text" value="${subscriberEmail}">`;
      }

      const input = parentEl.querySelector('.inserted_input')
      input.focus();
      input.addEventListener('blur', this.handleBlur.bind(this));
    }
  }

  handleBlur(event) {
    const insertedInput = event.target;

    if (!insertedInput.classList.contains("inserted_input")) {
      return;
    }

    const subscriberID = insertedInput.dataset.id;
    const newEmail = insertedInput.value;
    const oldEmail = insertedInput.parentElement.dataset.email;

    if (newEmail !== oldEmail) {
      this.updateSubscriberEmail(subscriberID, newEmail);
    } else {
      this.hideInputsForSubscriberEmail(newEmail);
    }
  }

  updateSubscriberEmail(subscriberID, newEmail) {
    const requestData = {
      subscribers: {
        email: newEmail,
      },
      _method: "PUT",
    };

    fetch(`/subscribers/${subscriberID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Csrf-Token": getCSRFToken(),
      },
      body: JSON.stringify(requestData),
    })
      .then(() => {
        this.hideInputsForSubscriberEmail(newEmail);
      })
      .catch((error) => {
        console.error("Error:", error);
        this.hideInputsForSubscriberEmail(newEmail);
      });
  }

  hideInputsForSubscriberEmail(emailVal = "") {
    const insertedInputs = document.querySelectorAll(".inserted_input");
    insertedInputs.forEach((el) => {
      const parentElement = el.closest(".subscribed_email");
      if (emailVal.length === 0) {
        emailVal = parentElement.dataset.email;
      }

      parentElement.querySelector('.inserted_input').remove();

      parentElement.dataset.email = emailVal;
      parentElement.innerHTML = `${emailVal} <i class="remove-subscribed-email">X</i>`;
      parentElement.querySelector('.remove-subscribed-email').addEventListener('click', this.removeSubscriber.bind(this));
    });
  }

  removeSubscriber(event) {
    const target = event.target;

    if (target.classList.contains('remove-subscribed-email')) {
      const parentElement = target.parentElement;

      const subscriberID = parentElement.dataset.id;

      fetch('/subscribers/' + subscriberID, {
        method: 'DELETE',
        headers: {
          "X-Csrf-Token": getCSRFToken(),
        },
      })
        .then((response) => response.json())
        .then(() => {
          parentElement.remove();
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }
}
