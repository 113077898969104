import { getCSRFToken } from "./common_functions";

function initRangeReports() {
  const daterange = document.querySelector('input[name="datetimes"]');

  if (daterange) {
    const setupConfig = {
      element: daterange,
      setup: {
        setup(picker) {
          picker.on("select", () => {
            let dateFrom = picker.getStartDate("DD MMM YYYY");
            let dateTo = picker.getEndDate("DD MMM YYYY");

            if (new Date(dateFrom).getTime() < new Date(dateTo).getTime()) {
              setDatesToStorage(dateFrom, dateTo, "updated");
            } else if (
              new Date(dateFrom).getTime() === new Date(dateTo).getTime()
            ) {
              const options = {
                year: "numeric",
                month: "short",
                day: "numeric",
              };
              const formattedDateTo = new Date(dateTo)
                .addDays(1)
                .toLocaleDateString("en-BR", options);
              setDatesToStorage(dateFrom, formattedDateTo, "updated");
            }

            newUserSearch();
          });
        },
      },
    };

    initDatePicker(setupConfig);
  }
}

function newUserSearch() {
  let url = "/users/new_users";
  let dates = JSON.parse(localStorage.getItem("dates"));
  let data = {
    affiliate: document.querySelector("#affiliates").value,
    period: document.querySelector("#period").value,
    date_from: dates.from,
    date_to: dates.to,
  };
  sendRequestWithParams(url, data, function () {
    drawNewUserChart("average-amount", 1000);
    drawNewUserChart("approval-count", 1);
    drawNewUserChart("one-approval", 1);
  });
}

function handleResponse(responseText) {
  const existingScript = document.getElementById("script");

  if (existingScript) {
    document.head.removeChild(existingScript);
  }

  const newScript = document.createElement("script");
  newScript.setAttribute("type", "text/javascript");
  newScript.id = "script";

  newScript.textContent = `
      (function() {
        ${responseText}
      })();
    `;

  document.head.appendChild(newScript);
}

function sendRequestWithParams(url, params, callback) {
  let data = JSON.parse(localStorage.getItem("filterParams"));
  data = Object.assign(data, params);
  localStorage.setItem("filterParams", JSON.stringify(data));

  let queryString = Object.entries(data)
    .map(([key, value]) => {
      if (typeof value === "object") {
        return Object.entries(value)
          .map(
            ([nestedKey, nestedValue]) =>
              encodeURIComponent(`${key}[${nestedKey}]`) +
              "=" +
              encodeURIComponent(nestedValue)
          )
          .join("&");
      } else {
        return encodeURIComponent(key) + "=" + encodeURIComponent(value);
      }
    })
    .join("&");

  fetch(`${url}?${queryString}&_=${Date.now()}`, {
    method: "GET",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "X-Csrf-Token": getCSRFToken(),
      Accept: "text/javascript, application/javascript",
      redirect: "follow",
    },
  })
    .then((response) => {
      return response.text();
    })
    .then((responseText) => {
      handleResponse(responseText);
    })
    .catch((error) => console.error("Script loading error:", error));
}

function drawNewUserChart(id, step) {
  const ctx = document.getElementById(id).getContext("2d");
  Chart.defaults.global.legend.display = false;

  new Chart(ctx, {
    type: "horizontalBar",
    data: {
      labels: Array.from(document.querySelectorAll("." + id + "-x")).map(
        (el) => el.textContent
      ),
      datasets: [
        {
          data: Array.from(document.querySelectorAll("." + id + "-y")).map(
            (el) => el.textContent
          ),
          backgroundColor: "#36A2EB",
          borderColor: "#36A2EB",
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: step,
            },
          },
        ],
      },
    },
  });
}

function initReportDatepicker() {
  const daterangeReports = document.querySelectorAll(".daterange.reports");

  if (!!daterangeReports?.length) {
    setDatesToLinks(new Date().addDays(-7), new Date());

    daterangeReports.forEach((el) => {
      const setupConfig = {
        element: el,
        setup: {
          setup(picker) {
            picker.on("select", () => {
              let dateFrom = picker.getStartDate("DD MMM YYYY");
              let dateTo = picker.getEndDate("DD MMM YYYY");

              setDatesToLinks(dateFrom, dateTo);
            });
            picker.on("view", () => {
              let dateFrom = picker.getStartDate("DD MMM YYYY");
              let dateTo = picker.getEndDate("DD MMM YYYY");

              setDatesToLinks(dateFrom, dateTo);
            });
          },
        },
      };

      initDatePicker(setupConfig);
    });
  }
}

function setDatesToLinks(dateFrom, dateTo) {
  let generateLinks = document.querySelectorAll(".generate_report");
  generateLinks?.forEach(function (link) {
    let linkHref = link.getAttribute("href");
    let parsedLink = new URL(window.location.origin + linkHref);
    let newLink =
      parsedLink.pathname +
      parsedLink.search.split("&")[0] +
      "&date_from=" +
      dateFrom +
      "&date_to=" +
      dateTo;
    link.setAttribute("href", newLink);
  });
}

document.addEventListener("turbo:load", function () {
  initRangeReports();
  initReportDatepicker();

  document.querySelectorAll(".search")?.forEach(function (element) {
    element.addEventListener("change", function () {
      let self = this,
        resourceKind = self.getAttribute("id").split("_search_value")[0],
        baseUrl = window.location.pathname.split("/"),
        selectValue = document.getElementById(
          self.getAttribute("id").split("_value")[0] + "_key"
        ),
        data = {
          search: {
            key: selectValue.options[selectValue.selectedIndex].value,
            value: self.value,
            app_kind: resourceKind,
          },
        };
      baseUrl.pop();

      let url =
        window.location.origin + baseUrl.join("/") + "/search_applications";

      sendRequestWithParams(url, data, function () {
        localStorage.setItem("filterParams", JSON.stringify({}));
      });
    });
  });

  document.querySelectorAll(".new_user_search")?.forEach(function (element) {
    element.addEventListener("change", function () {
      newUserSearch();
    });
  });

  document
    .querySelector("#users_search_button")
    ?.addEventListener("click", function () {
      let url = "/users/search";
      let data = {
        search_key: document.querySelector("#users_search_key").value,
        search_val: document.querySelector("#users_search").value,
      };

      sendRequestWithParams(url, data, function () {});
    });

  localStorage.setItem("filterParams", JSON.stringify({}));

  document.querySelectorAll(".filtering").forEach(function (element) {
    element.addEventListener("click", function (e) {
      let classes = this.className.split(" ");
      document.querySelectorAll("." + classes[1]).forEach(function (item) {
        item.classList.remove("active");
      });
      this.classList.add("active");
    });
  });

  if (document.getElementById("new-user-charts")) {
    drawNewUserChart("average-amount", 1000);
    drawNewUserChart("approval-count", 1);
    drawNewUserChart("one-approval", 1);
  }

  document.querySelectorAll(".copy-public-token")?.forEach(function (element) {
    element.addEventListener("click", function () {
      copyTextToClipboard("public_token", "Public Key copied successful!");
    });
  });

  document.querySelectorAll(".copy-private-token")?.forEach(function (element) {
    element.addEventListener("click", function () {
      copyTextToClipboard("secret_token", "Private Key copied successful!");
    });
  });

  function copyTextToClipboard(selector, toastText) {
    let copyText = document.getElementById(selector);
    copyText.select();

    try {
      navigator.clipboard
        .writeText(copyText.value)
        .then(function () {
          alert(toastText);
          let successElement = document.querySelector(
            "." + selector + "-success"
          );
          successElement.classList.remove("hide");

          setTimeout(function () {
            successElement.classList.add("hide");
          }, 1000);
        })
        .catch(function (err) {
          console.error("Failed to copy text:", err);
        });
    } catch (err) {
      console.error("Clipboard API not available:", err);
    }
  }
});

document.addEventListener("turbo:before-fetch-request", function (event) {
  if (event.detail.url.pathname.includes("search")) {
    event.preventDefault();

    fetch(event.detail.url.href, {
      method: event.detail.fetchOptions.method,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-Csrf-Token": getCSRFToken(),
        Accept: "text/javascript, application/javascript",
      },
    })
      .then((response) => {
        return response.text();
      })
      .then((responseText) => {
        handleResponse(responseText);
      })
      .catch((error) => console.error("Script loading error:", error));
  }
});
