import {getCSRFToken} from "./common_functions";

document.addEventListener("turbo:load", function () {
  document.body.addEventListener('click', function (e) {
    if (e.target.classList.contains('flexshopper-refund-link')) {
      e.preventDefault();
      refund_table(e.target, 'flexshopper');
    } else if (e.target.classList.contains('paytomorrow-refund-link')) {
      e.preventDefault();
      let elem = document.querySelector('#refundModal');
      document.querySelector('.paytomorrow-refund-block').classList.remove('d-none');
      elem.setAttribute('data-la-id', e.target.getAttribute('data-la-id'));
      elem.setAttribute('data-provider', 'paytomorrow');
    } else if (e.target.classList.contains('aff-refund-link')) {
      e.preventDefault();
      refund_table(e.target, 'aff');
    } else if (e.target.classList.contains('acima-refund-link')) {
      e.preventDefault();
      refund_table(e.target, 'acima');
    } else if (e.target.classList.contains('uown-refund-link')) {
      e.preventDefault();
      refund_table(e.target, 'uown');
    } else if (e.target.classList.contains('okinus-refund-link')) {
      e.preventDefault();
      refund_table(e.target, 'okinus');
    } else if (e.target.classList.contains('snap-refund-link')) {
      e.preventDefault();
      refund_table(e.target, 'snap');
    } else if (e.target.classList.contains('refund-btn')) {
      let response = confirm('Are you sure that you want to refund application?');
      if (!response) {
        return;
      }
      let elem = document.querySelector('#refundModal'),
        leaseAppID = elem.getAttribute('data-la-id'),
        provider = elem.getAttribute('data-provider'),
        items = {};
      if (provider === 'flexshopper' || provider === 'uown' || provider === 'aff' || provider === 'acima' || provider === 'okinus') {
        let refundedItems = [];
        document.querySelectorAll('.refund_quantity_input').forEach(function (item) {
          let parentEl = item.closest('tr'),
            quantity = item.value,
            sku = parentEl.querySelector('td:nth-child(2)').textContent;
          refundedItems.push({ sku: sku, quantity: quantity });
        });
        items = { items: refundedItems };
      } else if (provider === 'paytomorrow') {
        let reason = document.querySelector('.paytomorrow-refund-input').value;
        items = { reason: reason };
      } else if (provider === 'snap') {
        let refund_amount = document.querySelector('.snap-refund-input').value;
        items = { refund_amount: refund_amount };
      }
      sendRequestToRefund(leaseAppID, items);
    } else if (e.target.classList.contains('close-modal')) {
      clearRefundModalInputs();
    }
  });

  function refund_table(element, provider) {
    let elem = document.querySelector('#refundModal'),
      itemsInOrder = element.closest('tr.head').nextElementSibling.querySelectorAll('.collapsed-order-items'),
      clonedItems = [];
    document.querySelector('.order-items-table tbody').innerHTML = '';
    document.querySelector('.order-items-table').classList.remove('d-none');
    if (itemsInOrder.length) {
      for (let i = 0; i < itemsInOrder.length; i++) {
        let quantityInput = "<td><input type='text' class='refund_quantity_input' value='0' placeholder='Amount'></td>",
          defaultItems = itemsInOrder[i].cloneNode(true).querySelectorAll('td'),
          newItem = "<tr><td>" + defaultItems[0].textContent +
            "</td><td>" + defaultItems[5].textContent +
            "</td><td class='text-center'>" + defaultItems[1].textContent +
            "</td>" + quantityInput + "</tr>";
        clonedItems.push(newItem);
      }
    }
    elem.setAttribute('data-la-id', element.getAttribute('data-la-id'));
    elem.setAttribute('data-provider', provider);
    document.querySelector('.order-items-table tbody').innerHTML += clonedItems.join('');
  }

  function callbackCloseRefundModal() {
    clearRefundModalInputs();
    document.querySelector('.close').click();
  }

  function clearRefundModalInputs() {
    document.querySelector('.order-items-table tbody').innerHTML = '';
    document.querySelector('.order-items-table').classList.add('d-none');
    document.querySelector('.paytomorrow-refund-block').classList.add('d-none');
    document.querySelector('.paytomorrow-refund-input').value = '';
    document.querySelector('.acima-refund-block').classList.add('d-none');
    document.querySelector('.acima-refund-input').value = '';
    document.querySelector('.acima-refund-number-input').value = '0';
    document.querySelector('.okinus-refund-block').classList.add('d-none');
    document.querySelector('.okinus-refund-input').value = '';
    document.querySelector('.okinus-refund-number-input').value = '0';
    document.querySelector('.snap-refund-block').classList.add('d-none');
    document.querySelector('.snap-refund-input').value = '';
  }

  function sendRequestToRefund(leaseAppID, params) {
    fetch('/lease_applications/' + leaseAppID + '/refund', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-Csrf-Token": getCSRFToken(),
      },
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        alert(data.message);
        callbackCloseRefundModal();
        if (data.data !== undefined)
          alert(data.data);
      })
      .catch(error => {
        alert('Error: ' + error);
        callbackCloseRefundModal();
      });
  }
});
